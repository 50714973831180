* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*, ::after, ::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* overflow: hidden !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 1.8rem;
  font-family: 'Baloo Chettan 2', cursive;
  font-weight: 400;
  /* color: #a4acc4;
  background: #10121b; */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  background-color: #383838;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b; 
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #636363; 
}

a {
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:active, a:focus, a:hover {
  text-decoration: none;
  outline: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

b, strong {
  font-weight: bolder;
}

h1, h2, h3, h4, h5, h6 {
  color: #fff;
  margin-top: 0;
  margin-bottom: .5rem;
}

h1 {
  font-size: 3.2857rem;
  line-height: 4rem;
}

h2 {
  font-size: 2.57rem;
  line-height: 3.2857rem;
}

h3 {
  font-size: 2rem;
  line-height: 2.7rem;
}

h4 {
  font-size: 1.71rem;
  line-height: 2.43rem;
}

h5 {
  font-size: 1.43rem;
  line-height: 2.14rem;
}

h6 {
  font-size: 1.14rem;
  line-height: 1.857rem;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button, input, select {
  height: 50px;
  padding: 0 15px;
}

input, select, textarea {
  width: 100%;
  font-size: 14px;
  vertical-align: middle;
}

button, input, select, textarea {
  background: transparent;
  border: 1px solid #2e344e;
  font-family: 'Baloo Chettan 2', cursive;
  color: #a4acc4;
}

button, input {
  overflow: visible;
}

textarea {
  padding: 10px 15px;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #a4acc4;
  font-family: 'Baloo Chettan 2', cursive;
  transition: background-color 5000s ease-in-out 0s;
}

blockquote {
  border-left: 5px solid #037fff;
  font-size: 18px;
  line-height: 27px;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0,0,0, .1);
  position: relative;
  z-index: 1;
  margin-left: 25px;
  background: #191d2b;
}

.svg-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  /* transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  flex-shrink: 0;
  user-select: none;
  transition: all .4s ease-out;
}